.wallet-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 4px solid white;
    box-shadow: inset;
    min-height: 100px;
    min-width: 400px;
    margin: 0 16px 80px 16px;
}

.wallet-viewer-label {
    font-size: 24px;
    padding: 8px 11px;
    align-self: start;
}

.wallet-viewer-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.wallet-viewer-message {
    margin: 30px 40px 50px 40px;
    text-align: center;
    width: 100%;
    font-size: 1.8vw;
}

.tos-nft-card {
    display: flex;
    flex-direction: column;
    margin: 12px;
    max-width: 800px;
}


.tos-nft-card-label {
    font-size: 32px;
    font-weight: bold;
    margin: 12px;
}