.towerbox-main-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
}

.towerbox-bottom-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 20px;
}

.towerbox-move {
    font-size: 40px;
    font-weight: 700;
}

.towerbox-button {
    font-size: 40px;
    font-weight: 700;
    padding: 6px 40px;
    margin: 0 20px;
    cursor: pointer;
    border-radius: 12px;
}

.towerbox-view-link {
    font-size: 26px;
    font-weight: 700;
    padding: 6px 20px;
    border-radius: 8px;
    border: white solid 3px;
    cursor: pointer;
    border-radius: 12px;
    background-color: #707070;
}