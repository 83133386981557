.wallet-connect-container {
    margin: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wallet-connect-button {
    background-color: #5446B8;
    border-radius: 20px;
    border: none;
    color: white;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    min-width: 250px;
}

.wallet-connect-nft-count {
    font-size: 2vw;
    font-weight: bold;
    margin: 20px;
}