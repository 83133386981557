@font-face {
  font-family: 'solana';
  src: local('solana'), url(./assets/wormbox-rounded.woff) format('woff');
}

body {
  background-color: #282c34;
  color: white;
}

/* p {
  font-size: 1.5vw;
  padding: 0px 10vw;
  margin: 3vh 0 0 0;
} */

h2 {
  padding: 0px 10vw;
}

p {
  font-size: 16px;
  padding: 0px 10vw;
  margin: 3vh 0 0 0;
}

@media screen and (min-width: 320px) {
  p {
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
  h2 {
    font-size: calc(40px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  p {
    font-size: 30px;
  }
  h2 {
    font-size: 60px;
  }
}


.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  padding-top: 20px;
}

.App-header-title {
  font-size: 11vw;
  font-family: 'solana';
  margin: 0;
}

.App-link {
  color: #61dafb;
  padding: 20px 0;
}

a {
  text-decoration: none;
  color: white;
}